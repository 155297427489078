@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img {
  width: 100%;
}

body {
  background-color: #000;
  color: #fff;
  font-family: "Inter", sans-serif;
}

.App {
  overflow: hidden;
}

.text-gradient-1 {
  background: linear-gradient(262deg, #fbbc19 -8.01%, #fa014c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-2 {
  background: linear-gradient(260deg, #ff005c -13.37%, #d44c00 109.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-3 {
  background: linear-gradient(265deg, #f90 -5.84%, #8c0223 111.58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

$animationSpeed: 35s;

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  // height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(600px * 6);
    gap: 20px;
  }

  // .slide {
  //   height: 100px;
  //   width: 250px;
  // }
}
